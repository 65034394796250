import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { ParagraphXSmall } from 'baseui/typography';
import React, { useState } from 'react';
import { GetSolarGuideSection } from '../../types/homepage';
import GuideSectionForm from './guide-section-form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Lead } from '../../types';
import { ZohoServiceImpl } from '../../services/api/zoho.service';
import InfoModal, { InfoModalTypes } from '../modal/info-modal';
import Button from '../button/button';
import { navigate } from 'gatsby';
import { useWindow } from '../../context/use-window';
import { useQueryParam } from '../../context/use-query-string';
import theme from '../../page-layouts/theme';

declare type GuideFormProps = {
  data: GetSolarGuideSection;
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(16)
    .required('Please Enter First name'),
  lastName: Yup.string().required('Please Enter Last name'),
  email: Yup.string()
    .email('Please enter a valid Email address')
    .required('Please enter your Email address'),
  phoneNumber: Yup.string()
    .required('Please Enter Phone number')
    .min(10, 'Please Enter valid phone number')
    .max(10, 'Please Enter valid phone number'),
});

const GetGuide: React.FC<GuideFormProps> = ({ data }) => {
  const [css] = useStyletron();
  const { windowDimensions } = useWindow();
  const campaignName = useQueryParam('utm_campaign');
  const campaignMedium = useQueryParam('utm_medium');
  const gclid = useQueryParam('gclid');

  const [modal, setModal] = useState<InfoModalTypes>({
    isOpen: false,
  });

  const formik = useFormik({
    initialValues: {
      batteryGuide: false,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      solarGuide: false,
      systemType: '',
    },
    validationSchema,
    onSubmit: async values => {
      let message: string;
      let title: string;
      try {
        const lead: Lead = {
          campaignName,
          campaignMedium,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phoneNumber.toString(),
          sendBatteryGuide: `${values.batteryGuide}`,
          sendsolarGuide: `${values.solarGuide}`,
          systemType: values.systemType,
          leadSource: 'Website Form',
          leadSourceDetails: 'Get Guide',
          GCLID: gclid,
        };
        const { error } = await new ZohoServiceImpl().createLead(lead);
        title = error ? 'Sorry' : 'Thank you';
        message = error
          ? 'Failed to share guide, Please try again later'
          : 'Shortly you will get email with information.';
      } catch (e) {
        title = 'Sorry';
        message = 'Something went wrong, Please try again later';
      }

      title === 'Thank you'
        ? navigate('/thank-you-guide')
        : setModal({
            isOpen: true,
            title,
            message,
          });
    },
  });

  const paddings =
    windowDimensions.width && windowDimensions.width < 1400
      ? ['scale900', 'scale900', 'scale900', 'scale2400']
      : ['scale900', 'scale900', 'scale900', 'scale4800'];

  return (
    <Block
      flex={1}
      paddingLeft={paddings}
      paddingRight={paddings}
      justifyContent="center"
      display="flex"
      flexDirection="column"
    >
      <GuideSectionForm formik={formik} />
      <ParagraphXSmall
        marginBottom={'scale800'}
        color={theme.colors.contentInversePrimary}
        className={css({ textAlign: 'justify' })}
      >
        {data.Description}
      </ParagraphXSmall>
      <Button
        type="submit"
        kind="primary"
        onClick={formik.submitForm}
        disabled={formik.isSubmitting}
      >
        {data.Link.Text}
      </Button>
      <InfoModal
        message={modal.message}
        title={modal.title}
        isOpen={modal.isOpen}
        setIsOpen={value =>
          setModal({
            isOpen: value,
          })
        }
      />
    </Block>
  );
};

export default GetGuide;
