import { Accordion, Panel } from 'baseui/accordion';
import React, { useState } from 'react';
import { Block } from 'baseui/block';
import { Category, ContactInfo, Faq, QA } from '../../../types/resources';
import { GetSolarGuideSection } from '../../../types/homepage';
import AccordionTitle from './accordion-title';
import FAQsContactInfo from './contact-info';
import theme from '../../../page-layouts/theme';
import { useWindow } from '../../../context/use-window';
import Markdown from '../../mark-down/mark-down';
import VideoCategoryList from '../../video-library/video-category-list';
import VimeoPlayer from '../../video-library/vimeo-player';
import NewsSection from '../../news-and-blogs/news-section/news-section';
import { getBogsByCategory } from '../../../utils/blog-utils';
import { GetGuideSection } from '../../get-guide-section/get-guide-section';

declare type FAQsProps = {
  articles: any;
  videos: Category[];
  contactInfo: ContactInfo;
  getSolarGuideSection: GetSolarGuideSection;
  data: Faq;
};

const FAQs: React.FC<FAQsProps> = ({
  articles,
  contactInfo,
  data,
  getSolarGuideSection,
  videos,
}) => {
  const { deviceType } = useWindow();
  const [isExpanded, setIsExpanded] = useState<boolean[]>(
    data.QA.map(() => false),
  );

  const [isOpen, setIsOpen] = useState(false);
  const [currentVideo, setVideo] = useState<number>();
  const handleVideoSelect = (video: number) => {
    setVideo(video);
    setIsOpen(true);
  };

  const handleOnChange = (index: number) => {
    setIsExpanded(prevIsExpanded => {
      const newIsExpanded = prevIsExpanded.map((value, i) => {
        if (i === index) {
          return !value;
        } else {
          return value;
        }
      });
      return newIsExpanded;
    });
  };

  return (
    <Block
      display={'flex'}
      backgroundColor={'white'}
      paddingTop={['0', '0', 'scale1200']}
      flexDirection={'column'}
    >
      <Block
        display={'flex'}
        flexDirection={['column', 'column', 'row']}
        paddingLeft={['0', '0', 'scale1200', 'scale1200']}
        paddingRight={['0', '0', 'scale1200', 'scale1200']}
      >
        <Block
          flex={1}
          paddingLeft={['scale850', 'scale850', 'scale1400']}
          paddingTop={['scale400', 'scale400', '0']}
          paddingRight={['scale850', 'scale850', 'scale0']}
        >
          <Block>
            {data &&
              data.QA.map((qaItem: QA, index: number) => (
                <Accordion
                  key={index}
                  accordion={false}
                  overrides={{
                    Header: {
                      style: () => ({
                        color: theme.colors.accent600,
                      }),
                    },
                    ToggleIcon: {
                      style: () => ({
                        display: 'none',
                      }),
                    },
                    Root: {
                      style: () => ({
                        marginTop: deviceType === 'desktop' ? 'scale1200' : '0',
                      }),
                    },
                    Content: {
                      style: () => ({
                        color: theme.colors.accent600,
                        fontSize: theme.sizing.scale550,
                        paddingTop: 0,
                      }),
                    },
                    ContentAnimationContainer: {
                      style: () => ({
                        outline: theme.colors.accent700,
                      }),
                    },
                  }}
                >
                  <Panel
                    onChange={() => {
                      handleOnChange(index);
                    }}
                    key={index}
                    title={
                      <AccordionTitle
                        isExpanded={isExpanded[index]}
                        question={qaItem.Question}
                      />
                    }
                  >
                    <Markdown
                      data={qaItem.Answer}
                      color={theme.colors.accent600}
                      lineHeight={
                        deviceType === 'desktop'
                          ? theme.sizing.scale700
                          : theme.sizing.scale600
                      }
                    />
                  </Panel>
                </Accordion>
              ))}
          </Block>
        </Block>
      </Block>
      <Block
        flexDirection={'column'}
        paddingLeft={['0', '0', 'scale1200', 'scale1200']}
        paddingRight={['0', '0', 'scale1200', 'scale1200']}
      >
        <VideoCategoryList
          handleVideoSelect={handleVideoSelect}
          data={videos}
          isFaq={true}
        />
      </Block>
      {currentVideo && isOpen && (
        <VimeoPlayer
          video={currentVideo}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      <Block>
        <Block marginTop={'scale850'} display={'flex'} flexDirection={'column'}>
          <Block backgroundColor={theme.colors.backgroundPrimary}>
            <NewsSection
              data={getBogsByCategory(articles.strapi.blogs.data).map(
                category => ({
                  ...category,
                  Blogs: category.Blogs.slice(0, 3),
                }),
              )}
            />
          </Block>
        </Block>
        <GetGuideSection data={getSolarGuideSection} />
        <FAQsContactInfo contactInfo={contactInfo} />
      </Block>
    </Block>
  );
};

export default FAQs;
