import { HeadingLarge, LabelMedium } from 'baseui/typography';
import React from 'react';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';
import { Category } from '../../types/video-library';
import VideoListDesktop from './video-list-desktop';
import VideoListMobile from './video-list-mobile';
import theme from '../../page-layouts/theme';


declare type VideoCategoriesProps = {
  data: Category[];
  handleVideoSelect: (video: number) => void;
  isFaq?: boolean;
};

const VideoCategoryList: React.FC<VideoCategoriesProps> = ({
  data,
  handleVideoSelect,
  isFaq,
}) => {
  const [css] = useStyletron();

  return (
    <>
      {data?.map(category => (
        <Block key={category.Title} className={css({ zIndex: 0 })}>
          <HeadingLarge
            display={['none', 'none', 'none', 'block']}
            marginLeft="scale600"
            color={isFaq ? theme.colors.accent : theme.colors.white}
          >
            {category.Title}
          </HeadingLarge>
          <LabelMedium
            display={['block', 'block', 'block', 'none']}
            marginLeft="scale600"
            marginTop={'scale900'}
            color={isFaq ? theme.colors.accent : theme.colors.white}
          >
            {category.Title}
          </LabelMedium>
          <VideoListDesktop
            category={category}
            handleVideoSelect={handleVideoSelect}
          />
          <VideoListMobile
            category={category}
            handleVideoSelect={handleVideoSelect}
          />
        </Block>
      ))}
    </>
  );
};

export default VideoCategoryList;

