import { Block } from 'baseui/block';
import React, { useState } from 'react';
import { SEO } from '../components';
import FooterSection from '../components/footer';
import NavBar from '../components/nav-bar/nav-bar';
import FAQs from '../components/resource-and-faqs/faqs/faqs';
import PageLayout from '../page-layouts';
import theme from '../page-layouts/theme';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { ResourcesPageServiceImpl } from '../services/page/resources-service';
import { Homepage } from '../types/homepage';
import { ResourcesPage } from '../types/resources';
import FAQsBanner from '../components/resource-and-faqs/faqs/faqs-banner';
import { graphql } from 'gatsby';

declare type ResourcesPageProps = {
  serverData: any;
  data: any;
};

const Resources: React.FC<ResourcesPageProps> = ({ serverData, data }) => {
  const homepage = serverData.homePageData as Homepage;
  const resourcesPageData = serverData.resourcePageData as ResourcesPage;

  return (
    <PageLayout>
      <NavBar position={'fixed'} data={homepage.data.attributes.Header} />
      <FAQsBanner banner={resourcesPageData.data.attributes.banner} />
      {resourcesPageData.data.attributes.seo && <SEO data={resourcesPageData.data.attributes.seo} />}
      {/* removing the section temperorily - should add once approved. 
       <Block
        backgroundColor={theme.colors.backgroundPrimary}
        paddingBottom={['scale2400', 'scale2400', 'scale2400', 'scale3200']}
        paddingTop={['scale2400', 'scale2400', 'scale2400', 'scale3200']}
      >
      {resourcesPageData.data.attributes.Resources.map((item, index) => (
          <Category
            key={index}
            category={item.Category}
            content={item.Content}
          />
        ))}
      </Block>  */}
      <Block backgroundColor={theme.colors.backgroundPrimary}>
        <FAQs
          articles={data}
          contactInfo={resourcesPageData.data.attributes.contactInfo}
          data={resourcesPageData.data.attributes.faqs}
          getSolarGuideSection={resourcesPageData.data.attributes.getSolarGuideSection}
          videos= {resourcesPageData.data.attributes.categories}
        />
      </Block>
      <FooterSection data={homepage.data.attributes.Footer} />
    </PageLayout>
  );
};

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const serviceObject = new ResourcesPageServiceImpl();

  const res = await Promise.all([
    serviceObject.getResourcesPage(),
    homeServiceObject.getCommonData(),
  ]);

  return {
    props: {
      resourcePageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};
export const query = graphql`
  {
    strapi {
      blogs(pagination: { limit: 200, start: 0 }, sort: "PublishedOn") {
        data {
          attributes {
            Author
            Banner {
              Background {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            Conclusion {
              Conclusion
              H1
            }
            Category
            Content
            H1
            H2
            PublishedOn
            Slug
            createdAt
            publishedAt
            updatedAt
            Description
          }
        }
      }
    }
  }
`;
export default Resources;
