import { Block } from 'baseui/block';
import React from 'react';
import { GetSolarGuideSection } from '../../types/homepage';
import GetGuide from './get-guide';
import Headings from './headings';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { StaticImage } from 'gatsby-plugin-image';
import { useStyletron } from 'baseui';
import theme from '../../page-layouts/theme';

export type GetGuideSectionProps = {
  data: GetSolarGuideSection;
};

export const GetGuideSection: React.FC<GetGuideSectionProps> = ({ data }) => {
  const [css] = useStyletron();

  return (
    <Block
      height={['auto', 'auto', 'auto', '100%']}
      paddingTop={['scale1000', 'scale1000', 'scale1000', 'scale1200']}
      paddingBottom={['scale1000', 'scale1000', 'scale1000', 'scale1200']}
      width={'100%'}
      backgroundSize={'cover'}
      position={'relative'}
      backgroundRepeat={'no-repeat'}
      display="flex"
      flexDirection={'column'}
      justifyContent="center"
      backgroundColor={theme.colors.mono500}
    >
      <Block
        display={'flex'}
        flexDirection="column"
        position={'relative'}
        width={['100%']}
        height={'100%'}
        justifyContent="center"
      >
        <Headings data={data} />
        <FlexGrid flexGridColumnCount={[1, 1, 1, 2]}>
          <FlexGridItem>
            <GetGuide data={data} />
          </FlexGridItem>
          <FlexGridItem
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Block
              flex={1}
              marginRight={['auto', 'auto', 'auto', 'scale4800']}
              marginLeft={['auto', 'auto', 'auto', 'scale4800']}
              display={['none', 'none', 'none', 'block']}
            >
              <StaticImage
                className={css({
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                })}
                src="../../images/solar_guide_media.webp"
                alt="media"
              />
            </Block>
          </FlexGridItem>
        </FlexGrid>
      </Block>
    </Block>
  );
};
